
import { initialState } from './initialState';
export default function (state = initialState, action = {}) {
  switch (action.type) {
    case 'CREATE_CONTACT_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'CREATE_CONTACT_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        conatct_data: action.payload.data,
        error: false,
      };

    case 'CREATE_CONTACT_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };
   
    default:
      return {
        ...state,
      };
  }
}
