import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';

export default function(ComposedComponent) {
  class RequireAuth extends Component {
    render() {
      return (
        <>
          {this.props.newAppVersion ? (
            <Modal id="new-app-version" isOpen={true}>
              <ModalHeader>
                {this.props.newAppVersion.name}
              </ModalHeader>
              <ModalBody>
                {this.props.newAppVersion.message}
              </ModalBody>
              <ModalFooter>
                <Button color="primary" size="sm" onClick={() => {
                  localStorage.setItem('set-temp-UIVersion', null);
                  window.location.reload(true);
                }}>Reload</Button>
              </ModalFooter>
            </Modal>
          )
          : null}
          <ComposedComponent {...this.props} />
        </>
      )
    }
  }

  function mapStateToProps(state) {
    return { newAppVersion: state.home && state.home.newAppVersion };
  }

  return connect(mapStateToProps)(RequireAuth);
}