import axios from 'axios';
import {setCookie,getCookie,delete_cookie} from '../utils/setCookie';
const json = require('../version.json');

let token = localStorage.getItem('auth_token');
let tempVer = localStorage.getItem('set-temp-UIVersion');
let navigateLang = window.navigator.userLanguage || window.navigator.language;

window.setTempUIVersion = v => {
  localStorage.setItem('set-temp-UIVersion', v);
  let rty = localStorage.getItem('set-temp-UIVersion');
  console.log(`New temp UI version ${rty} saved. Reload to use it.`)
}
  
var splitLanguge = navigateLang.split("-");
if (splitLanguge.length > 1) {
  navigateLang = splitLanguge[0]
}

if(process.env.REACT_APP_HOST_ENV === "production" && (getCookie('panel') == null || getCookie('panel') ===undefined) ){
  delete_cookie('panel');
  setCookie('panel',"gg.tgm.cloud",365)
  const user_lang= "en"
  setCookie('panel_langauge', user_lang,365)
}
if(process.env.REACT_APP_HOST_ENV === "staging" && (getCookie('panel') == null || getCookie('panel') ===undefined)){
  delete_cookie('panel')
  setCookie('panel',"gg.staging.tgm.cloud",365)
}
  
function makeHeaders() {
  let headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    //   "Access-Control-Allow-Credentials": true
  }
  return headers;
}

function makeHeaderWithToken() {
  let headers = {
    "Authorization": `Bearer ${token}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    "Access-Control-Allow-Credentials": true,
    "X-Client-Version": tempVer && tempVer !== 'null'? tempVer : json.version,
  }
  return headers;
}
console.log('API.JS called')
export default axios.create({
    baseURL: `https://${getCookie('panel')}/tgm-api/`,
    headers: token ? makeHeaderWithToken() : makeHeaders(),
});
  