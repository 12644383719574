import { initialState } from './initialState';


export default function (state = initialState, action = {}) {
  switch (action.type) {
    case 'AUTH_VERIFY_LOADING':
    return {
      ...state,
      isLoading: true,
      success: false,
      error: false,
    };
    case 'AUTH_VERIFY_SUCCESS':
    return {
      ...state,
      isLoading: false,
      success: true,
      error: false,
      auth_verify: action.payload.data,
      icons: [],
    };

    case 'AUTH_VERIFY_ERROR':
    return {
      ...state,
      isLoading: false,
      success: false,
      error: action.payload.response ? action.payload.response.data : action.payload.data,
    };
    ////
    case 'GET_SURVAYS_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'GET_SURVAYS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        userData: action.payload && [...state.userData, ...action.payload.data],
        totalAvailableSurveys: action.payload && action.payload.headers["x-pagination-total-count"],
        error: false,
      };

    case 'GET_SURVAYS_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };

    case 'RESET_AVAILABLE_SURVEYS':
      return {
        ...state,
        userData: [],
        totalAvailableSurveys: null,
      };

    case 'NEW_APP_VERSION':
      return {
        ...state,
        newAppVersion: action.payload,
      };

    case 'GET_PROFILE_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'GET_PROFILE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        error: false,
        get_profile: action.payload && action.payload.data,
      };

    case 'GET_PROFILE_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };
  
    case 'GET_PRIVACY_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'GET_PRIVACY_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        privacyData: action.payload.data,
        error: false,
      };

    case 'GET_PRIVACY_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };
      case 'EMAIL_CONFIRM_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'EMAIL_CONFIRM_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        email_confirm: action.payload.data,
        error: false,
      };
    case 'EMAIL_CONFIRM_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };
      case 'CLOSE_ACCOUNT_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'CLOSE_ACCOUNT_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        close_account: action.payload.data,
        error: false,
      };

    case 'CLOSE_ACCOUNT_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };
      case 'SAR_GDPR_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'SAR_GDPR_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        sar_gdpr: action.payload.data,
        error: false,
      };

    case 'EMAIL_CHANGE_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };
      case 'EMAIL_CHANGE_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'EMAIL_CHANGE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        email_change: action.payload.data,
        error: false,
      };

    case 'SAR_GDPR_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };
      
      case 'GDPR_RECORD_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'GDPR_RECORD_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        gdpr_change: action.payload.data,
        error: false,
      };

    case 'GDPR_RECORD_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };
      
      case 'LOGIN_HISTORY_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'LOGIN_HISTORY_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        loginHistoryData: action.payload.data,
        error: false,
      };

    case 'LOGIN_HISTORY_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };
      
      
    // case 'LOGOUT_USER':
    //   return {
    //     ...state,
    //     isLoading: false,
    //     success: true,
    //     userData: '',
    //     error: false,
    //   };
    default:
      return {
        ...state,
      };
  }
}
