/* eslint-disable no-unused-expressions */
/* eslint no-undef: "off"*/
// eslint-disable-next-line
import React, { useEffect } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import {setCookie,getCookie} from '../../utils/setCookie';
import { setPwaStatistics } from '../../utils/chekCover';
import LoaderSVG from '../../assets/images/loader_animate.gif';
import { getLink } from '../../utils/chekCover';
import RequireAuth from '../../components/ErrorModal'
import PrivateRoute from './PrivateRoute'
import RestrictedRoute from './RestrictedRoute'
import { lazyWithPreload } from '../../utils/lazyLoading';

getLink();

const Login = lazyWithPreload(() => import('../../components/Login'));
const Home = lazyWithPreload(() => import('../../components/Home/index'));
const Rewards = lazyWithPreload(() => import('../../components/Rewards'));
const SurveyHistory = lazyWithPreload(() => import('../../components/SurveyHistory'));

const Referral = lazyWithPreload(() => import("../../components/ReferralProgram"));
const Help = lazyWithPreload(() => import("../../components/Help"));
const Profile = lazyWithPreload(() => import("../../components/userProfile"));
const Policy = lazyWithPreload(() => import("../../components/Policy"));
const TermnCondition = lazyWithPreload(() => import("../../components/TermsandCondition"));
const Finished = lazyWithPreload(() => import("../../components/Finished"));
const Activation = lazyWithPreload(() => import("../../components/ActivationAccount"));

const ProfileUnsubscribe = lazyWithPreload(() => import("../../components/UnsubscribeAcoount"));
const GdprInfo = lazyWithPreload(() => import("../../components/GDPR"));
const NotFound = lazyWithPreload(() => import("../../components/404-notFound"));
const Affiliate = lazyWithPreload(() => import("../../components/Affiliate"));

let Panel_language = getCookie('panel_langauge')

if(Panel_language === null || Panel_language === undefined || Panel_language === "") {
  Panel_language ="en"
}
if(window.location.pathname.split("/")[1] ==="login") {
  Panel_language ="en"
}

localStorage.setItem("end-page-url",window.location.pathname);
localStorage.setItem("active-user-url",window.location.pathname);
localStorage.setItem("unsubscribe-user-url",window.location.pathname);

const isLoggedIn = (path) => {
  const keepmeLogged = localStorage.getItem('keepmeLogged');
  let isAuth = false;
  if (!!keepmeLogged && keepmeLogged.toString() === 'true') {
    if (window.location.pathname.split("/")[1] === path || window.location.pathname.split("/")[2] === path) {
      isAuth = true
    }
  }
  return isAuth
}

export default withRouter(function App({ location }) {
  
  useEffect(() => {
    if (window.userpilot) window.userpilot.reload();
  }, [location.pathname]);

  if(window.location.pathname.split("/")[1] !== "login" && window.location.pathname.split("/")[1] !== "" ){
    setCookie('url_languge',window.location.pathname.split("/")[1],365)
  }

  if(localStorage.getItem('auth_token')){
    // tell server if the pwa is installed.
    setPwaStatistics();
  }

  return (
    <React.Fragment>
      <React.Suspense fallback={<div className="pageLoaderUpdated"><img src={LoaderSVG} alt="Loading.."/></div>}>
        <Switch>
          <Route  path={'/:id/finished/:status?'}  name="finished" render={() => {
            ReactGA.pageview(window.location.pathname + window.location.search);
            return (
              <Finished/>
            )}} />
          <Route  path={'/:id/activate'}  name="activation" render={() => {
            ReactGA.pageview(window.location.pathname + window.location.search);
            return (
              isLoggedIn('activate') ? (
                <Activation/>
              ) 
              : (<Login/>)
            )}} />
          <Route  path={'/:id/unsubscribe'}  name="unsubscribe" render={() => {
            ReactGA.pageview(window.location.pathname + window.location.search);
            return (
              isLoggedIn('unsubscribe') ? (
                <ProfileUnsubscribe/>
              ) 
              : (<Login/>)
            )}} />
          <Route  path={'/:id/login'}  name="Login" render={() => {
            ReactGA.pageview(window.location.pathname + window.location.search);
            return ( 
              (!!localStorage.getItem('keepmeLogged') && localStorage.getItem('keepmeLogged').toString() === 'true') ? (
                <Redirect to={`/${Panel_language}/home`} />
              ) 
              : (<Login/>)
            )}} />
          <Route exact path='/:id/home/privacy-policy' render={
              ({ match }) => (
                <Redirect to={`/${Panel_language}/privacy-policy`}/>
              )
          }/>
          <Route exact path='/:id/home/terms-and-conditions' render={
              ({ match }) => (
                <Redirect to={`/${Panel_language}/terms-and-conditions`}/>
              )
          }/>

          <PrivateRoute exect path={'/:id/home'} name="home" component={RequireAuth(Home)} />
          <RestrictedRoute exect path={'/:id/affiliate'} name="affiliate" component={RequireAuth(Affiliate)} />
          <PrivateRoute exact path={'/:id/rewards/:thankyou?'} name="Rewards" component={RequireAuth(Rewards)} />
          <PrivateRoute exact path={'/:id/history'} name="SurveyHistory" component={RequireAuth(SurveyHistory)} />
          <PrivateRoute exact path="/:id/referral-program" name="referral-program" component={RequireAuth(Referral)} />
          <PrivateRoute exact path="/:id/help" name="help" component={RequireAuth(Help)} />
          <PrivateRoute exact path="/:id/profile" name="profile" component={RequireAuth(Profile)} />
          <PrivateRoute exact path="/:id/privacy-policy" name="privacy-policy" component={RequireAuth(Policy)} />
          <PrivateRoute exact path="/:id/terms-and-conditions" name="terms-and-conditions" component={RequireAuth(TermnCondition)} />
          <PrivateRoute exact path="/:id/gdpr-sar" name="gdpr-ifo" component={RequireAuth(GdprInfo)} />

          <Route exact path='/' render={
              ({ match }) => (
                (!!localStorage.getItem('keepmeLogged') && localStorage.getItem('keepmeLogged').toString() === 'true') ?
                  <Redirect to={`/${Panel_language}/home`} />
                :
                 <Redirect to={`/${Panel_language}/login`}/>
              )
          }/>
          <Route exact path='/login' render={
              ({ match }) => (
                (!!localStorage.getItem('keepmeLogged') && localStorage.getItem('keepmeLogged').toString() === 'true') ?
                  <Redirect to={`/${Panel_language}/home`} />
                :
                  <Redirect to={`/${Panel_language}/login`}/>
              )
          }/>
          <Route path='/finished/:st?' render={
              ({ match }) => {
                let completed = ""
                if (match.params.st === "10" || match.params.st === "fin"){
                  completed = "/completed"
                }
                return (
                  <Redirect to={`/${Panel_language}/finished${completed}`}/>
                )}
          }/>
          <Route path='/activate' render={
              ({ match }) => (
                <Redirect to={`/${Panel_language}/activate`}/>
              )
          }/>
          <Route path='/unsubscribe' render={
              ({ match }) => (
                <Redirect to={`/${Panel_language}/unsubscribe`}/>
              )
          }/>
          <Route component={NotFound}/>
        </Switch>
      </React.Suspense>
    </React.Fragment>
  );
})
