import {getCookie,expireAllCookies} from './setCookie';
import api from '../actions/api';

const handleLogOut = () => {
  api.get('auth/logout').then((response) => {
    if (response.value.status === 200) {
      expireAllCookies('auth_token', ['/', `/${getCookie("panel_langauge")}`,"/en",localStorage.getItem('end-page-url')]);
      expireAllCookies('keepmeLogged', ['/', `/${getCookie("panel_langauge")}`,"/en",localStorage.getItem('end-page-url')]); // clear previously stored cookie.
      expireAllCookies('panel_langauge', ['/', `/${getCookie("panel_langauge")}`,"/en",localStorage.getItem('end-page-url')]);
                
    // preserve firebase notification token value after logout so that can be used when login again.
    let tokenVal = localStorage.getItem('notification-token');
    localStorage.clear();
    localStorage.setItem('notification-token',tokenVal);
    window.location.reload();
    }
  }).catch((error) => {
    expireAllCookies('auth_token', ['/', `/${getCookie("panel_langauge")}`,"/en",localStorage.getItem('end-page-url')]);
    expireAllCookies('keepmeLogged', ['/', `/${getCookie("panel_langauge")}`,"/en",localStorage.getItem('end-page-url')]); // clear previously stored cookie.
    expireAllCookies('panel_langauge', ['/', `/${getCookie("panel_langauge")}`,"/en",localStorage.getItem('end-page-url')]);
                
    // preserve firebase notification token value after logout so that can be used when login again.
    let tokenVal = localStorage.getItem('notification-token');
    localStorage.clear();
    localStorage.setItem('notification-token',tokenVal);
    window.location.reload();
  })
 
}

export default {
  setupInterceptors: (store) => {
    const localToken = localStorage.getItem('auth_token');

    api.interceptors.request.use(
      config => {
        if (localToken) {
          config.headers.Authorization = `Bearer ${localToken}`
        }
        return config;
      }
    );

    // Add a response interceptor
    api.interceptors.response.use(function (response) {
        return response;
    }, function (err) {
      const error = err.response;
      // if error is 401 
      if (error && error.status===401) {
        // TODO Need to check why we are reloading page after invalid token
        const url_segment = localStorage.getItem("end-page-url").split("/")
        if (!url_segment.includes("finished")){
          console.log("[Token expired] logging out... ", error, window.location.pathname)
          handleLogOut();
        }
      } else if(error && error.status===409) {
        store.dispatch({ type: 'NEW_APP_VERSION', payload: error.data })
      }
      return Promise.reject(error);
    });

  }
}