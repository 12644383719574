
import { initialState } from './initialState';
export default function (state = initialState, action = {}) {
  switch (action.type) {
    case 'PROFILE_UNSUBCRIBE_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'PROFILE_UNSUBCRIBE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        profile_unsubscribe: action.payload.data,
        error: false,
      };

    case 'PROFILE_UNSUBCRIBE_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error:action.payload,
      };
    default:
      return {
        ...state,
      };
  }

}
