
import { initialState } from './initialState';
export default function (state = initialState, action = {}) {
  switch (action.type) {
    case 'PROFILE_ACTIVATE_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'PROFILE_ACTIVATE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        profile_activate: action.payload.data,
        error: false,
      };

    case 'PROFILE_ACTIVATE_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error:action.payload,
      };

    //   case 'GET_OPPORTUNITY_LOADING':
    //   return {
    //     ...state,
    //     isLoading: true,
    //     success: false,
    //     error: false,
    //   };

    // case 'GET_OPPORTUNITY_SUCCESS':
    //   return {
    //     ...state,
    //     isLoading: false,
    //     success: true,
    //     opportunity_list: action.payload.data,
    //     error: false,
    //   };

    // case 'GET_OPPORTUNITY_ERROR':
    //   return {
    //     ...state,
    //     isLoading: false,
    //     success: false,
    //     error: action.payload.response ? action.payload.response.data : action.payload.data,
    //   };
   
    default:
      return {
        ...state,
      };
  }

}
