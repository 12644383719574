import firebase from 'firebase/app'
import 'firebase/messaging'
import { isMobile } from 'react-device-detect'

export const initializeFirebase = (sender_id) => {
	const config = {
		messagingSenderId: sender_id
	};
	if (!firebase.apps.length) {
		firebase.initializeApp(config);
	}
};

export const sendTokenToServer = (REGISTRATION_TOKEN, AUTH_TOKEN, panel_name) => {
	const data = {
		token: REGISTRATION_TOKEN,
		type: isMobile ? 'mobile' : 'desktop',
	}
	return fetch(`https://${panel_name}/tgm-api/push/add`, {
	  method: 'POST',
	  headers: new Headers({
		'Authorization': 'Bearer ' + AUTH_TOKEN,
		'Content-Type': 'application/json'
	  }),
	  body: JSON.stringify(data)
	}).catch(error => {
	  console.error(' - Save token to server Err - ', error);
	})
}

export const askForPermissionToReceiveNotifications = async (AUTH_TOKEN, panel_name) => {
	try{
		const messaging = firebase.messaging();
		await messaging.requestPermission();
		const token = await messaging.getToken();
		messaging.onMessage(payload => {
			console.log('[message Received]');
			const notification =  payload.notification;
			if (notification) {
				const notificationTitle = notification.title || "Notification";
				navigator.serviceWorker.getRegistration('/firebase-messaging-sw.js').then((registration) => {
					if(registration){
						registration.showNotification(notificationTitle, notification); 
					}
				});
			}
		});

		messaging.onTokenRefresh(() => {
			messaging.getToken().then((refreshedToken) => {
				localStorage.setItem("notification-token", refreshedToken);
				if (refreshedToken) {
					sendTokenToServer(refreshedToken, AUTH_TOKEN, panel_name).then(saveRes => {
						console.log('[ Notification token refreshed ]');
					});
				} else {
					// Show permission request.
					console.log('No Instance ID token available. Request permission to generate one.');
				}
			}).catch((err) => {
				console.log('Unable to retrieve refreshed token ', err);
			});
		});

		return token;
	} catch (error){
		console.log(error);
	}
};