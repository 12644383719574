export const initialState = {
  isLoading: false,
  success: false,
  error: false,
  // token: '',
  message: '',
  paymentPlans: [],
  userData: [],
  emailStatus: {},
  profile_activate:{},
  close_account:{},
  profile_unsubscribe:{},
  email_change:{},
  cover_photo:{},
  sar_gdpr:{},
  gdpr_change:{},
  totalAvailableSurveys: null,
  finished_survay: [],
  survay_history_data: [],
  recived_prize_data: [],
  translatedArticles: [],
  nps_feedback: [],
  affiliate_accounts_data: [],
};