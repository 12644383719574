import { createStore, applyMiddleware, compose } from 'redux';
import { createPromise } from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';
import persistState from 'redux-localstorage';
import rootReducer from './reducers';

const createStoreWithMiddleware = compose(
  persistState('auth'),
  applyMiddleware(
    thunkMiddleware,
    createPromise({
      promiseTypeSuffixes: ['LOADING', 'SUCCESS', 'ERROR']
    })
  ),
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
)(createStore);

export default function configureStore() {
  const store = createStoreWithMiddleware(rootReducer);
  return store;
}