const isEmptyOrNull = (obj) => {
    if (obj === null || obj === "") {
        return true;
    } else {
        for(let key in obj) {
            if(obj.hasOwnProperty(key)) return false;
        }
        return true;
    }
}

const openUrl = url => {
    const newWindow = window.open(url);

    if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        window.location.href = url;
    }
};

export { isEmptyOrNull, openUrl }