export const setCookie = function (name, value, days) {
    var d = new Date();
    d.setTime(d.getTime() + (days*24*60*60*1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = name + "=" + (value || "") + "; " + expires +"; path=/"; 
  //  document.cookie = name + "=" + (value || "")  + "; path=/";
  }
  export const getCookie = function (name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  export const deleteAllCookies =function () {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    window.location = "/"; 
}

export const delete_cookie =function (name) {
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
export const expireAllCookies =function(name,paths) {
  console.log("expire Cookie : ", name)
  var expires = new Date(0).toUTCString();

  // expire null-path cookies as well
  document.cookie = name + '=; expires=' + expires;

  for (var i = 0, l = paths.length; i < l; i++) {
      document.cookie = name + '=; path=' + paths[i] + '; expires=' + expires;
  }
}
