import { initialState } from './initialState';

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case 'GET_NEWS_ARTICLES_LOADING':
      return {
        ...state,
        articles: {},
        success: false,
        error: false,
      };

    case 'GET_NEWS_ARTICLES_SUCCESS':
      return {
        ...state,
        success: true,
        articles: action.payload && action.payload.data,
        error: false,
      };

    case 'GET_NEWS_ARTICLES_ERROR':
      return {
        ...state,
        articles: null,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };

    case 'GET_TRANSLATED_MESSAGE_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'GET_TRANSLATED_MESSAGE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        error: false,
      };

    case 'GET_TRANSLATED_MESSAGE_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };
  
      case 'UPDATE_TRANSLATED_MESSAGE':
        return {
          ...state,
          success: true,
          translatedArticles: action.payload && [...state.translatedArticles, action.payload],
          error: false,
        };

    case 'GET_MODAL_STATE':
      return {
        ...state,
        isShow: action.isOpen,
      };

    default:
      return {
        ...state
      };
  }
}
