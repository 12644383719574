
import { initialState } from './initialState';
export default function (state = initialState, action = {}) {
  switch (action.type) {
    case 'GET_SURVEY_HISTORY_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'GET_SURVEY_HISTORY_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        survay_history_data: action.payload && [...state.survay_history_data, ...action.payload.data],
        totalSurveysHistory: action.payload && action.payload.headers["x-pagination-total-count"],
        error: false,
      };

    case 'GET_SURVEY_HISTORY_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };

      case 'RESET_SURVEY_HISTORY':
        return {
          ...state,
          survay_history_data: [],
          totalSurveysHistory: null,
        };

    default:
      return {
        ...state,
      };
  }
}
