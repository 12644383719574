import React from 'react'
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PrivateRoute from './PrivateRoute'
import { lazyWithPreload } from '../../utils/lazyLoading';

const RestrictedRoute = ({restricted, userData, ...rest }) => {
  const Restricted = lazyWithPreload(() => import("../../components/Restricted"));
  if(restricted || !userData.user.has_affiliates) return <Route component={Restricted} />
  else return <PrivateRoute {...rest} />
}

const mapStateToProps = (state) => ({
  userData: state.auth && state.auth.userData
})

export default connect(mapStateToProps, {})(RestrictedRoute);
