/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-globals */
/* eslint no-undef: "off"*/
// eslint-disable-next-line

import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import './scss/style.scss';
// import './assets/style/app.scss';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import { initializeFirebase } from './push-notification';
import configureStore from './store';
import intercepter from './utils/interceptor'

const initGoogleAnalytics = ()=>{
  ReactGA.initialize('UA-122034733-6');
  ReactGA.set({ dimension1: "online" })
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const store = configureStore();
intercepter.setupInterceptors(store);
ReactDOM.render((
  <Provider store={store}>
    <BrowserRouter >
      <App />
    </BrowserRouter>
  </Provider>
), document.getElementById('root'));

// initialize react Google Analytics.
initGoogleAnalytics();

// initialize firebase for push notification.
let sender_id = null;
if (process.env.REACT_APP_HOST_ENV === 'production') {
  sender_id = process.env.REACT_APP_PRODUCTION_FCM_SENDER_ID;
} else{
  sender_id = process.env.REACT_APP_STAGING_FCM_SENDER_ID;
}
initializeFirebase(sender_id);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

// Register service worker for push notification.
if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js', {scope: 'firebase-messaging-sw'}).then(function(registration) {
    console.log('[firebase-messaging-sw] Registered, scope :', registration.scope);
  }).catch(function(err) {
    console.log('[firebase-messaging-sw] failed, error:', err);
  });
}