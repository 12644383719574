
import { initialState } from './initialState';
// const initialState = {
//   isLoading: false,
//   success: false,
//   error: false,
//   token: '',
//   message: '',
// };

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case 'FINISHED_SURVEY_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'FINISHED_SURVEY_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        finished_survay: action.payload && [...state.finished_survay, ...action.payload.data],
        totalFinishedSurveys: action.payload && action.payload.headers["x-pagination-total-count"],
        error: false,
      };

    case 'FINISHED_SURVEY_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };

    case 'RESET_FINISHED_SURVEY':
      return {
        ...state,
        finished_survay: [],
        totalFinishedSurveys: null,
      };

    case 'RECIVED_PRIZE_LOADING':
      return {
        ...state,
        isLoadingReward: true,
        successReward: false,
        errorReward: false,
      };

    case 'RECIVED_PRIZE_SUCCESS':
      return {
        ...state,
        isLoadingReward: false,
        successReward: true,
        errorReward: false,
        recived_prize_data: action.payload && [...state.recived_prize_data, ...action.payload.data],
        totalReceivedPrize: action.payload && action.payload.headers["x-pagination-total-count"],
      };

    case 'RECIVED_PRIZE_ERROR':
      return {
        ...state,
        isLoadingReward: false,
        successReward: false,
        errorReward: action.payload.response ? action.payload.response.data : action.payload.data,
      };

    case 'RESET_RECIVED_PRIZE':
      return {
        ...state,
        recived_prize_data: [],
        totalReceivedPrize: null,
      };

    case 'GET_PRIZE_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'GET_PRIZE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        get_prize_Data: action.payload.data,
        error: false,
      };

    case 'GET_PRIZE_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };

    case 'GET_PAYMENT_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'GET_PAYMENT_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        get_payment_data: action.payload.data,
        error: false,
      };

    case 'GET_PAYMENT_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };
    
    case 'GET_PAYMENT_PTS_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'GET_PAYMENT_PTS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        get_payment_pts_data: action.payload.data,
        error: false,
      };

    case 'GET_PAYMENT_PTS_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };

    case 'GET_PAYMENT_DETAIL_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'GET_PAYMENT_DETAIL_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        payment_detail: action.payload.data,
        error: false,
      };

    case 'GET_PAYMENT_DETAIL_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };

    case 'UPDATE_PROFILES_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'UPDATE_PROFILES_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        update_profile_detail: action.payload && action.payload.data,
        error: false,
      };

    case 'UPDATE_PROFILES_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };

    case 'SMS_SEND_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'SMS_SEND_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        sms_send_detail: action.payload.data,
        error: false,
      };

    case 'SMS_SEND_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };

    case 'REDEEME_REQUEST_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'REDEEME_REQUEST_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        redeem_request_detail: action.payload.data,
        error: false,
      };

    case 'REDEEME_REQUEST_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };

    case 'REDEEME_PTS_REQUEST_LOADING':
      return {
        ...state,
        isRedeemPtsLoading: true,
        success: false,
        error: false,
      };

    case 'REDEEME_PTS_REQUEST_SUCCESS':
      return {
        ...state,
        isRedeemPtsLoading: false,
        success: true,
        redeem_pts_request_detail: action.payload.data,
        error: false,
      };

    case 'REDEEME_PTS_REQUEST_ERROR':
      return {
        ...state,
        isRedeemPtsLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };  

    default:
      return {
        ...state,
      };
  }
}
