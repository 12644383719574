import { initialState } from './initialState';


export default function (state = initialState, action = {}) {
  switch (action.type) {
    case 'USER_AUTH_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'USER_AUTH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        userData: action.payload.data,
        error: false,
      };
      
    case 'USER_AUTH_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };
    case 'GET_TRANSLATOR_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'GET_TRANSLATOR_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        error: false,
        language: action.payload.data,
      };

    case 'GET_TRANSLATOR_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };
    case 'GET_LANGUAGE_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'GET_LANGUAGE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        languageData: action.payload.data,
        error: false,
      };

    case 'GET_LANGUAGE_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };
      case 'LOG_OUT_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'LOG_OUT_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        userData:"",
        language:"",
        error: false,
      };

    case 'LOG_OUT_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };
    case 'CHANGE_PASSWORD_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'CHANGE_PASSWORD_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        change_password: action.payload.data,
        error: false,
      };

    case 'CHANGE_PASSWORD_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };
     
      case 'PASSWORD_RESET_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'PASSWORD_RESET_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        password_reset: action.payload.data,
        error: false,
      };

    case 'PASSWORD_RESET_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };

      case 'PASSWORD_SET_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'PASSWORD_SET_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        password_set: action.payload.data,
        error: false,
      };

    case 'PASSWORD_SET_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };
      case 'GET_USER':
      let userData=state.userData
      let actionPayload =action.payload
      userData.user={...state.userData.user,...actionPayload}
      return {
        ...state,
        isLoading: false,
        success: true,
        userData: userData,
        error: false
      };
    default:
      return {
        ...state,
      };
  }
}
