import axios from 'axios';
import {getCookie} from './setCookie';
import {isEmptyOrNull} from './utility';

    const getLink = () => {
        if(localStorage.getItem('coverPhoto')){
            return localStorage.getItem('coverPhoto')
        }
        else{
            const coverUrl = !!getCookie('panel') ? `https://${getCookie('panel')}/tgm-api/cover` : `https://gg.tgm.cloud/tgm-api/cover`;
            axios.get(coverUrl, { responseType:"blob" })
            .then((response)=> {
                var reader = new window.FileReader();
                reader.readAsDataURL(response.data); 
                reader.onload =()=> {
                    localStorage.setItem('coverPhoto',reader.result)
                    return reader.result
                }
            }).catch(error => {
                console.error('Fetching CoverImg - ', error);
            });
        }
    };

    const setPwaStatistics = () => {
        console.warn('#_#_# TRY SAVE PWA ');
        if (window.matchMedia('(display-mode: standalone)').matches) {
            console.warn('#-#-# IT IS PWA ');
            const config = {
                headers: {
                    "content-type": "multipart/form-data",
                    "Authorization": `Bearer ${localStorage.getItem('auth_token')}`
                },
            }; 

            const url = !!getCookie('panel') ? `https://${getCookie('panel')}/tgm-api/stat` : null;
            if(!isEmptyOrNull(url)) {
                axios.post(url,{'pwa' : 1},config).then(res => {
                    if (!!res && res.status === 200) {
                        console.log('SUCCESS: PWA status saved to server : ', res.data);
                    }
                }, err => {
                    console.warn('PWA status save Failed ', err);
                }).catch((err) => {
                    console.warn('PWA status save error ', err);
                });
            }
        }else {
            console.warn('#-#-# NOT PWA ');
        }
    };

export {getLink, setPwaStatistics};