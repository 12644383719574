
import { initialState } from './initialState';

export default function (state = initialState, action = {}) {
  switch (action.type) {
    case 'UPDATE_PROFILES_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'UPDATE_PROFILES_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        profile_Data: action.payload && action.payload.data,
        error: false,
      };

    case 'UPDATE_PROFILES_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };

      case 'STORE_BUTTON_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'STORE_BUTTON_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        store_button_Data: action.payload.data,
        error: false,
      };

    case 'STORE_BUTTON_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };

      case 'STORE_IMAGE_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'STORE_IMAGE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        store_image_Data: action.payload.data,
        error: false,
      };

    case 'STORE_IMAGE_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };

      case 'DEL_IMAGE_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'DEL_IMAGE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        del_image_Data: action.payload.data,
        error: false,
      };

    case 'DEL_IMAGE_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };

      case 'GET_IMAGE_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'GET_IMAGE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        get_image_Data: action.payload.data,
        error: false,
      };

    case 'GET_IMAGE_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };
      case 'GET_CATEGORY_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'GET_CATEGORY_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        get_category_Data: action.payload && action.payload.data,
        error: false,
      };

    case 'GET_CATEGORY_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };
   
      case 'GET_ANSWER_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'GET_ANSWER_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        get_answer_Data: action.payload && action.payload.data,
        error: false,
      };

    case 'GET_ANSWER_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };
      case 'SET_PERCENT_LOADING':
      return {
        ...state,
        isLoading: true,
        success: false,
        error: false,
      };

    case 'SET_PERCENT_SUCCESS':
      return {
        ...state,
        isLoading: false,
        success: true,
        set_percent_Data: action.payload.data,
        error: false,
      };

    case 'SET_PERCENT_ERROR':
      return {
        ...state,
        isLoading: false,
        success: false,
        error: action.payload.response ? action.payload.response.data : action.payload.data,
      };
    default:
      return {
        ...state,
      };
  }
}
