import React from 'react';
import ReactGA from 'react-ga';
import { Route, Redirect } from 'react-router-dom';
import { lazyWithPreload } from '../../utils/lazyLoading';

const PrivateRoute = ({component: Component, ...rest }) => {
  ReactGA.pageview(rest.location.pathname + window.location.search);
  let translator = JSON.parse(localStorage.getItem('translated-text'));
  return (
    <Route
      {...rest}
      render={(props) => {
        if(localStorage.getItem('auth_token')) {
          const NotificationModel = lazyWithPreload(() => import('./notificationModel'));
          const Header = lazyWithPreload(() => import('../../components/Header/index'));
          return (
            <><NotificationModel className="fullscreen-notification-modal" translator={translator}/><Header/><Component {...props} /></>
          )
        } else {
          return (<Redirect to={{ pathname:`/` , state: { from: props.location } }} />)
        }}
      }
    />
  );
}

export default PrivateRoute
