import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import loginReducer from './loginReducer';
import homeReducer from './homeReducer';
import rewardReducer from './rewardReducer';
import survay_historyReducer from './survay_historyReducer';
import referralReducer from './referralReducer';
import helpReducer from './helpReducer';
import profileReducer from './profileReducer';
import finished_pageReducer from './finished_pageReducer';
import profile_actvateReducer from './profile_actvateReducer';
import unsubscibe_pageReducer from './unsubscibe_pageReducer';
import newsArticleReducer from './newsArticleReducer';
import npsFeedbackReducer from './npsFeedbackReducer';

const rootReducer = combineReducers({
  routing: routerReducer,
  form: formReducer,
  auth: loginReducer,
  home:homeReducer,
  rewards:rewardReducer,
  history:survay_historyReducer,
  referral:referralReducer,
  help:helpReducer,
  profile:profileReducer,
  survay_finished:finished_pageReducer,
  profile_actvate:profile_actvateReducer,
  profile_unsubscribe:unsubscibe_pageReducer,
  articles: newsArticleReducer,
  nps_feedback: npsFeedbackReducer,
});

export default rootReducer;
